'use client';
import {
	Button,
	Dialog,
	DialogContent,
	FormGroup,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Slide,
	TextField,
	useTheme,
	Divider,
	Box,
	Typography,
	DialogTitle,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useState } from 'react';
import { useNotification } from '../ErrorContext';
import useAuthFormData from './hooks/useAuthFormData';
import { AxiosError } from 'axios';
import { Close, Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { encryptAES, useClientTranslation } from '@negotium/common';
import { IAuth } from '@negotium/models';
import { signIn } from '@negotium/libCustomer';
import Image from 'next/image';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

type LoginProps = {
	open: boolean;
	onClose: () => void;
	lng: 'es' | 'en';
	onOpenRegister: () => void;
	onOpenForgetPassword: () => void;
};

export const Login = ({
	open,
	onClose,
	lng = 'es',
	onOpenRegister,
	onOpenForgetPassword,
}: LoginProps) => {
	const { t } = useClientTranslation(lng, ['common']);

	const theme = useTheme();

	const [loadingAuth, setLoadingAuth] = useState(false);
	const { setErrorNotification } = useNotification();
	const [showPassword, setShowPassword] = useState(false);
	const initialValues = {
		email: '',
		password: '',
	};
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};

	const onSubmit = async (values: IAuth): Promise<void> => {
		setLoadingAuth(true);

		let password: string = values.password;
		if (values?.password && process.env.NEXT_PUBLIC_ENCRYPT_KEY) {
			password = encryptAES(
				values.password,
				process.env.NEXT_PUBLIC_ENCRYPT_KEY
			);
		}
		signIn({
			email: values?.email,
			password,
		})
			.then((result) => {
				if (result) {
					setLoadingAuth(false);
					onClose();
				}
			})
			.catch((err) => {
				if (err instanceof AxiosError) {
					setErrorNotification(t(`${err?.response?.data.message}`));
				} else {
					setErrorNotification(t(err));
				}

				setLoadingAuth(false);
			});
	};

	const { formik, isLoading } = useAuthFormData(initialValues, onSubmit, lng);

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={onClose}
			aria-describedby='alert-dialog-slide-description'
			PaperProps={{
				sx: {
					backgroundColor: theme.palette.white?.main || 'white',
					py: 2,
					borderRadius: 6,
				},
			}}
			slotProps={{
				backdrop: {
					sx: {
						backgroundImage: {
							md: `url('/static/desktopBgModal.jpg')`,
							xs: `url('/static/movilBgModal.jpg')`,
						},
						backgroundSize: 'cover',

						backdropFilter: 'blur(10px)',
					},
				},
			}}
		>
			<DialogTitle>
				<Box display='flex' justifyContent='flex-end'>
					<Button
						onClick={() => onClose()}
						variant='contained'
						color='secondary'
						sx={{
							p: 0,
							minWidth: '30px',
							height: '30px',
							borderRadius: '50%',
						}}
					>
						<Close />
					</Button>
				</Box>
			</DialogTitle>
			<DialogContent>
				<form onSubmit={formik.handleSubmit}>
					<Grid container marginTop={2} rowSpacing={2}>
						<Grid item xs={12}>
							<Box sx={{ width: '25%', margin: 'auto' }}>
								<Image
									src='/static/logo_lacuadra.png'
									width={100}
									height={100}
									alt={process.env.NEXT_PUBLIC_NAME || ''}
									style={{ width: '100%', height: 'auto' }}
								/>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='Subtitle' textAlign='center' fontWeight=''>
								{t('loginOrRegister')}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<FormGroup>
								<InputLabel
									sx={{
										color: theme.palette.secondary.main || 'black',
										fontWeight: '600',
										ml: 3,
									}}
								>
									{t('email')}
								</InputLabel>
								<TextField
									id='email'
									placeholder='Eg. johndoe@gmail.com'
									value={formik.values.email}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									error={formik.touched.email && Boolean(formik.errors.email)}
									helperText={
										formik.touched.email &&
										formik.errors.email &&
										t(String(formik.errors.email))
									}
									fullWidth={false}
									margin='dense'
									color='secondary'
									sx={{
										borderRadius: 4,
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: (theme) => theme.palette.primary.dark,
											borderWidth: 2,
										},
										'& .MuiInputBase-input': {
											pl: `25px !important`,
										},
										'& > *': {
											borderRadius: `16px !important`,
										},
									}}
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={12}>
							<FormGroup>
								<InputLabel
									color='secondary'
									sx={{
										color: theme.palette.secondary.main || 'black',
										fontWeight: '600',
										ml: 3,
										mb: 1,
									}}
								>
									{t('password')}
								</InputLabel>
								<OutlinedInput
									type={showPassword ? 'text' : 'password'}
									name='password'
									endAdornment={
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge='end'
												color='primary'
											>
												{showPassword ? (
													<VisibilityOff color='secondary' />
												) : (
													<Visibility color='secondary' />
												)}
											</IconButton>
										</InputAdornment>
									}
									placeholder='********'
									value={formik.values.password}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									error={
										formik.touched.password && Boolean(formik.errors.password)
									}
									fullWidth={false}
									margin='dense'
									sx={{
										borderRadius: 4,
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: (theme) => theme.palette.primary.dark,
											borderWidth: 2,
										},
										'& .MuiInputBase-input': {
											pl: `25px !important`,
										},
										'& > *': {
											borderRadius: `16px !important`,
										},
									}}
								/>
								{formik.errors.password && formik.touched.password && (
									<FormHelperText sx={{ color: '#d32f2f' }}>
										{t(formik.errors.password)}
									</FormHelperText>
								)}
							</FormGroup>
						</Grid>

						<Grid
							item
							xs={12}
							sx={{
								marginTop: '15px',
								display: 'flex',
								justifyContent: 'center',
								flexDirection: 'column',
							}}
						>
							<Typography variant='ExplicationText' textAlign='center'>
								{t('forgotPass')}
							</Typography>
							<Button
								autoFocus
								onClick={() => onOpenForgetPassword()}
								variant='ButtonActionText'
								sx={{
									marginTop: '0px',
									pt: 0,
								}}
							>
								{t('recuperatePassword')}
							</Button>
						</Grid>

						<Grid item xs={12} display='flex' justifyContent='center'>
							<LoadingButton
								fullWidth
								type='submit'
								autoFocus
								loading={loadingAuth || isLoading}
								loadingPosition='end'
								variant='ButtonAcceptDialog'
								disabled={isLoading || loadingAuth}
							>
								{t('login')}
							</LoadingButton>
						</Grid>
						<Grid xs={12} item>
							<Divider
								sx={{
									borderColor: (theme) => theme.palette.secondary.light,
									borderWidth: 1,
									my: 2,
								}}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								display: 'flex',
								justifyContent: 'center',
								flexDirection: 'column',
							}}
						>
							<Typography variant='ExplicationText' textAlign='center'>
								{t('notHaveAccount')}
							</Typography>
							<Button
								autoFocus
								onClick={() => onOpenRegister()}
								variant='ButtonActionText'
								sx={{
									marginTop: '0px',
									pt: 0,
								}}
							>
								{t('register')}
							</Button>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	);
};
