'use client';
import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useClientTranslation } from '@negotium/common';
import { ICategoriesShow } from '@negotium/models';
import { useNotification } from '../ErrorContext';
import { getCategoriesParent } from '@negotium/libCustomer';
import { LoadingRectangle } from '../Skeleton';
import dynamic from 'next/dynamic';
import Slider from 'react-slick';

type SectionCategoriesProps = {
	lng: 'es' | 'en';
};

// const ArrowSlider = dynamic(
// 	() =>
// 		import('@negotium/componentsCustomer').then((module) => module.ArrowSlider),
// 	{ ssr: false }
// );
const CardCategory = dynamic(
	() =>
		import('@negotium/componentsCustomer').then(
			(module) => module.CardCategory
		),
	{ ssr: false }
);

export const SectionCategories = ({ lng }: SectionCategoriesProps) => {
	const { t } = useClientTranslation(lng, ['common', 'catalog']);

	const [categories, setCategories] = useState<Array<ICategoriesShow>>([]);

	const [loadingCategories, setLoadingCategories] = useState<boolean>(true);
	const { setErrorNotification } = useNotification();

	useEffect(() => {
		if (loadingCategories) {
			getCategoriesParent()
				.then((response) => {
					setCategories(response.docs);
					setLoadingCategories(false);
				})
				.catch(() => {
					setLoadingCategories(false);
					setErrorNotification(t('errorGetCategories'));
				});
		}
	}, [loadingCategories]);

	const settings = {
		dots: false,
		infinite: true,
		speed: 3000,
		autoplaySpeed: 3000,
		autoplay: true,
		slidesToShow: 5,
		slidesToScroll: 1,
		arrows: false,
		cssEase: 'linear',
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true,
					speed: 3000,
					autoplaySpeed: 3000,
					autoplay: true,
				},
			},
			{
				breakpoint: 500,
				settings: {
					arrows: false,
					speed: 3000,
					autoplaySpeed: 3000,
					autoplay: true,
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
				},
			},
		],
	};

	if (loadingCategories) {
		return (
			<Container
				maxWidth={false}
				sx={{
					py: 4,
				}}
			>
				<Container maxWidth='xl'>
					<Grid container spacing={2}>
						<Grid item xs={12} md={12}>
							<LoadingRectangle width={'20%'} height='5vh' />
						</Grid>
						{[...Array(3).keys()].map((item) => (
							<Grid item xs={12} md={4} key={item}>
								<LoadingRectangle width={'100%'} />
							</Grid>
						))}
					</Grid>
				</Container>
			</Container>
		);
	}

	if (categories.length === 0) {
		return null;
	}

	return (
		<Container
			maxWidth={false}
			sx={{
				py: 4,
			}}
		>
			<Container maxWidth='xl' sx={{ mb: 2 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography
							variant='TitleSection'
							my={2}
							textAlign='center'
							color='black'
						>
							{t('categoriesOfProducts')}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Slider {...settings}>
							{categories.length > 0 &&
								categories.map((category) => (
									<Box
										key={category.categoriesId}
										sx={{
											height: '100%',
											pb: 4,
										}}
									>
										<CardCategory lng={lng} category={category} />
									</Box>
								))}
						</Slider>
					</Grid>
				</Grid>
			</Container>
		</Container>
	);
};
