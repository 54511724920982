'use client';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormGroup,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Slide,
	TextField,
	Typography,
	useTheme,
	Box,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useEffect, useState } from 'react';
import { useNotification } from '../ErrorContext';
import { LoadingButton } from '@mui/lab';

import useChangePassword, { IChangePassword } from './hooks/useChangePassword';
import { Close, Visibility, VisibilityOff } from '@mui/icons-material';
import { useRouter } from 'next/navigation';
import { encryptAES, useClientTranslation } from '@negotium/common';
import { changePasswordClientUser } from '@negotium/libCustomer';
import Image from 'next/image';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

type ChangePasswordProps = {
	open: boolean;
	onClose: () => void;
	onOpenLogin: () => void;

	lng: 'es' | 'en';
	info: {
		email: string;
		code: string;
	} | null;
};

export const ChangePasswordDialog = ({
	open,
	onClose,
	onOpenLogin,
	lng = 'es',
	info,
}: ChangePasswordProps) => {
	const [loadingAuth, setLoadingAuth] = useState(false);
	const { t } = useClientTranslation(lng, ['common']);

	const theme = useTheme();

	const [showPassword, setShowPassword] = useState(false);
	const [showRePassword, setShowRePassword] = useState(false);
	const router = useRouter();

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleClickShowRePassword = () => setShowRePassword((show) => !show);

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};

	const { setErrorNotification, setSuccessNotification } = useNotification();
	const initialValues = {
		email: '',
		code: '',
		password: '',
		rePassword: '',
	};

	const onSubmit = async (values: IChangePassword): Promise<void> => {
		// try {
		const dataToSend: IChangePassword = { ...values };
		setLoadingAuth(true);
		if (values.password && process.env.NEXT_PUBLIC_ENCRYPT_KEY) {
			dataToSend.password = encryptAES(
				values.password,
				process.env.NEXT_PUBLIC_ENCRYPT_KEY
			);
		}
		changePasswordClientUser(
			dataToSend.password,
			dataToSend.email,
			dataToSend.code
		)
			.then((result) => {
				if (result.status === 200) {
					setLoadingAuth(false);
					setSuccessNotification(t('passwordRecovered'));
					onOpenLogin();
					onClose();
					router.push(`/${lng}`);
				} else {
					setLoadingAuth(false);
					setErrorNotification(t(result.message));
					setLoadingAuth(false);
				}
			})
			.catch((err) => {
				setErrorNotification(err?.message);
			})
			.finally(() => {
				setLoadingAuth(false);
			});
	};

	const { formik, isLoading } = useChangePassword(initialValues, onSubmit, lng);
	useEffect(() => {
		if (info) {
			if (info.email) {
				formik.setFieldValue('code', info.code);
				formik.setFieldValue('email', info.email);
			}
		}
	}, [info?.email]);

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={onClose}
			aria-describedby='alert-dialog-slide-description'
			PaperProps={{
				sx: {
					backgroundColor: theme.palette.white?.main || 'white',
					py: 2,
					borderRadius: 6,
				},
			}}
			slotProps={{
				backdrop: {
					sx: {
						backgroundImage: {
							md: `url('/static/desktopBgModal.jpg')`,
							xs: `url('/static/movilBgModal.jpg')`,
						},
						backgroundSize: 'cover',

						backdropFilter: 'blur(10px)',
					},
				},
			}}
		>
			<DialogTitle>
				<Box display='flex' justifyContent='flex-end'>
					<Button
						onClick={() => onClose()}
						variant='contained'
						color='secondary'
						sx={{
							p: 0,
							minWidth: '30px',
							height: '30px',
							borderRadius: '50%',
						}}
					>
						<Close />
					</Button>
				</Box>
			</DialogTitle>
			<DialogContent>
				<form onSubmit={formik.handleSubmit}>
					<Grid container rowSpacing={2}>
						<Grid item xs={12}>
							<Box sx={{ width: '25%', margin: 'auto' }}>
								<Image
									src='/static/logo_lacuadra.png'
									width={100}
									height={100}
									alt={process.env.NEXT_PUBLIC_NAME || ''}
									style={{ width: '100%', height: 'auto' }}
								/>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='Subtitle' textAlign='center' fontWeight=''>
								{t('changePassword')}
							</Typography>
							<Typography
								variant='ExplicationTextBold'
								my={3}
								textAlign='center'
							>
								{t('changePasswordDescription')}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<FormGroup>
								<InputLabel
									sx={{
										color: theme.palette.secondary.main || 'black',
										fontWeight: '600',
										ml: 3,
									}}
								>
									{t('code')}
								</InputLabel>
								<TextField
									color='secondary'
									id='code'
									name='code'
									placeholder='45603'
									value={formik.values.code}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									error={formik.touched.code && Boolean(formik.errors.code)}
									helperText={
										formik.touched.code &&
										formik.errors.code &&
										t(String(formik.errors.code))
									}
									fullWidth={false}
									margin='dense'
									sx={{
										borderRadius: 4,
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: (theme) => theme.palette.primary.dark,
											borderWidth: 2,
										},
										'& .MuiInputBase-input': {
											pl: `25px !important`,
										},
										'& > *': {
											borderRadius: `16px !important`,
										},
									}}
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={12}>
							<FormGroup>
								<InputLabel
									sx={{
										color: theme.palette.secondary.main || 'black',
										fontWeight: '600',
										ml: 3,
									}}
								>
									{t('password')}
								</InputLabel>
								<OutlinedInput
									id='password'
									type={showPassword ? 'text' : 'password'}
									endAdornment={
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge='end'
											>
												{showPassword ? (
													<VisibilityOff color='secondary' />
												) : (
													<Visibility color='secondary' />
												)}
											</IconButton>
										</InputAdornment>
									}
									color='secondary'
									placeholder='********'
									value={formik.values.password}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									error={
										formik.touched.password && Boolean(formik.errors.password)
									}
									fullWidth={false}
									margin='dense'
									sx={{
										mt: 1,
										borderRadius: 4,
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: (theme) => theme.palette.primary.dark,
											borderWidth: 2,
										},
										'& .MuiInputBase-input': {
											pl: `25px !important`,
										},
										'& > *': {
											borderRadius: `16px !important`,
										},
									}}
								/>
								{formik.errors.password && formik.touched.password && (
									<FormHelperText sx={{ color: '#d32f2f' }}>
										{t(formik.errors.password)}
									</FormHelperText>
								)}
							</FormGroup>
						</Grid>
						<Grid item xs={12}>
							<FormGroup>
								<InputLabel
									sx={{
										color: theme.palette.secondary.main || 'black',
										fontWeight: '600',
										ml: 3,
									}}
								>
									{t('rePassword')}
								</InputLabel>
								<OutlinedInput
									id='rePassword'
									type={showRePassword ? 'text' : 'password'}
									color='secondary'
									endAdornment={
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												onClick={handleClickShowRePassword}
												onMouseDown={handleMouseDownPassword}
												edge='end'
											>
												{showRePassword ? (
													<VisibilityOff color='secondary' />
												) : (
													<Visibility color='secondary' />
												)}
											</IconButton>
										</InputAdornment>
									}
									placeholder='********'
									value={formik.values.rePassword}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									error={
										formik.touched.rePassword &&
										Boolean(formik.errors.rePassword)
									}
									fullWidth={false}
									margin='dense'
									sx={{
										mt: 1,
										borderRadius: 4,
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: (theme) => theme.palette.primary.dark,
											borderWidth: 2,
										},
										'& .MuiInputBase-input': {
											pl: `25px !important`,
										},
										'& > *': {
											borderRadius: `16px !important`,
										},
									}}
								/>
								{formik.errors.rePassword && formik.touched.rePassword && (
									<FormHelperText sx={{ color: '#d32f2f' }}>
										{t(formik.errors.rePassword)}
									</FormHelperText>
								)}
							</FormGroup>
						</Grid>
						<Grid
							item
							xs={12}
							display='flex'
							justifyContent='space-evenly'
							sx={{
								marginTop: '20px',
							}}
						>
							<Button
								autoFocus
								onClick={() => onClose()}
								variant='ButtonCancelDialog'
								title={t('close')}
							>
								{t('close')}
							</Button>
							<LoadingButton
								type='submit'
								autoFocus
								fullWidth
								loading={loadingAuth}
								variant='ButtonAcceptDialog'
								disabled={isLoading || loadingAuth}
							>
								{t('recover')}
							</LoadingButton>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	);
};
