'use client';
import {
	Button,
	Dialog,
	DialogContent,
	FormGroup,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Slide,
	TextField,
	Typography,
	useTheme,
	Box,
	DialogTitle,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useState } from 'react';
import { useNotification } from '../ErrorContext';
import { v4 } from 'uuid';

import { AxiosError } from 'axios';
import { Close, Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import useRegisterFormData from './hooks/useRegisterFormData';
import { IPublicRegister } from '@negotium/models';
import {
	encryptAES,
	GenericServices,
	useClientTranslation,
} from '@negotium/common';
import Image from 'next/image';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

type RegisterProps = {
	open: boolean;
	onClose: () => void;
	onOpenLogin: () => void;
	onOpenActivateAccount: (email: string) => void;
	lng: 'es' | 'en';
};

export const Register = ({
	open,
	onClose,
	lng = 'es',
	onOpenLogin,
	onOpenActivateAccount,
}: RegisterProps) => {
	const { t } = useClientTranslation(lng, ['users', 'common']);

	const theme = useTheme();

	const [loading, setLoading] = useState(false);
	const { setErrorNotification, setSuccessNotification } = useNotification();

	const [showPassword, setShowPassword] = useState(false);
	const [showRePassword, setShowRePassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleClickShowRePassword = () => setShowRePassword((show) => !show);

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};

	const initialValues = {
		userId: v4(),
		name: '',
		lastName: '',
		email: '',
		phone: '',
		password: '',
		rePassword: '',
		ci: '',
	};

	const onSubmit = async (values: IPublicRegister): Promise<void> => {
		try {
			setLoading(true);

			let password: string = values.password;
			if (values?.password && process.env.NEXT_PUBLIC_ENCRYPT_KEY) {
				password = encryptAES(
					values.password,
					process.env.NEXT_PUBLIC_ENCRYPT_KEY
				);
			}
			await GenericServices('publicRegister', [], {
				...values,
				password,
			});
			setSuccessNotification(t('activationEmailSent', { ns: 'common' }));
			setLoading(false);
			onOpenActivateAccount(values.email);
			onClose();
		} catch (err) {
			if (err instanceof AxiosError) {
				setErrorNotification(t(`${err?.response?.data.message}`));
			}
			setLoading(false);
		}
	};

	const { formik, isLoading } = useRegisterFormData(
		initialValues,
		onSubmit,
		lng
	);

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={onClose}
			aria-describedby='alert-dialog-slide-description'
			PaperProps={{
				sx: {
					backgroundColor: theme.palette.white?.main || 'white',
					py: 2,
					borderRadius: 6,
				},
			}}
			slotProps={{
				backdrop: {
					sx: {
						backgroundImage: {
							md: `url('/static/desktopBgModal.jpg')`,
							xs: `url('/static/movilBgModal.jpg')`,
						},
						backgroundSize: 'cover',

						backdropFilter: 'blur(10px)',
					},
				},
			}}
		>
			<DialogTitle>
				<Box display='flex' justifyContent='flex-end'>
					<Button
						onClick={() => onClose()}
						variant='contained'
						color='secondary'
						sx={{
							p: 0,
							minWidth: '30px',
							height: '30px',
							borderRadius: '50%',
						}}
					>
						<Close />
					</Button>
				</Box>
			</DialogTitle>
			<DialogContent>
				<form onSubmit={formik.handleSubmit}>
					<Grid container rowSpacing={2} columnSpacing={1}>
						<Grid item xs={12}>
							<Box sx={{ width: '25%', margin: 'auto' }}>
								<Image
									src='/static/logo_lacuadra.png'
									width={100}
									height={100}
									alt={process.env.NEXT_PUBLIC_NAME || ''}
									style={{ width: '100%', height: 'auto' }}
								/>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='Subtitle' textAlign='center' fontWeight=''>
								{t('registerAction', { ns: 'common' })}
							</Typography>
						</Grid>
						<Grid item xs={12} md={6}>
							<FormGroup>
								<InputLabel
									sx={{
										color: theme.palette.secondary.main || 'black',
										fontWeight: '600',
										ml: 3,
									}}
								>
									{t('name')}
								</InputLabel>
								<TextField
									color='secondary'
									id='name'
									placeholder='Ej. John'
									fullWidth={false}
									margin='dense'
									name='name'
									value={formik.values.name}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									error={formik.touched.name && Boolean(formik.errors.name)}
									helperText={formik.touched.name && formik.errors.name}
									sx={{
										borderRadius: 4,
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: (theme) => theme.palette.primary.dark,
											borderWidth: 2,
										},
										'& .MuiInputBase-input': {
											pl: `25px !important`,
										},
										'& > *': {
											borderRadius: `16px !important`,
										},
									}}
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={12} md={6}>
							<FormGroup>
								<InputLabel
									sx={{
										color: theme.palette.secondary.main || 'black',
										fontWeight: '600',
										ml: 3,
									}}
								>
									{t('lastName')}
								</InputLabel>
								<TextField
									color='secondary'
									id='lastName'
									placeholder='Ej. Doe'
									fullWidth={false}
									margin='dense'
									name='lastName'
									value={formik.values.lastName}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									error={
										formik.touched.lastName && Boolean(formik.errors.lastName)
									}
									helperText={formik.touched.lastName && formik.errors.lastName}
									sx={{
										borderRadius: 4,
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: (theme) => theme.palette.primary.dark,
											borderWidth: 2,
										},
										'& .MuiInputBase-input': {
											pl: `25px !important`,
										},
										'& > *': {
											borderRadius: `16px !important`,
										},
									}}
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={12}>
							<FormGroup>
								<InputLabel
									sx={{
										color: theme.palette.secondary.main || 'black',
										fontWeight: '600',
										ml: 3,
									}}
								>
									{t('email')}
								</InputLabel>

								<TextField
									color='secondary'
									id='email'
									placeholder='user@NEXT_PUBLIC_DOMAIN.xxx'
									fullWidth={false}
									margin='dense'
									name='email'
									value={formik.values.email}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									error={formik.touched.email && Boolean(formik.errors.email)}
									helperText={formik.touched.email && formik.errors.email}
									sx={{
										borderRadius: 4,
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: (theme) => theme.palette.primary.dark,
											borderWidth: 2,
										},
										'& .MuiInputBase-input': {
											pl: `25px !important`,
										},
										'& > *': {
											borderRadius: `16px !important`,
										},
									}}
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={12} md={6}>
							<FormGroup>
								<InputLabel
									sx={{
										color: theme.palette.secondary.main || 'black',
										fontWeight: '600',
										ml: 3,
									}}
								>
									{t('phone')}
								</InputLabel>
								<TextField
									color='secondary'
									id='phone'
									placeholder='+53 534XXXXX'
									fullWidth={false}
									margin='dense'
									name='phone'
									value={formik.values.phone}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									error={formik.touched.phone && Boolean(formik.errors.phone)}
									helperText={formik.touched.phone && formik.errors.phone}
									sx={{
										borderRadius: 4,
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: (theme) => theme.palette.primary.dark,
											borderWidth: 2,
										},
										'& .MuiInputBase-input': {
											pl: `25px !important`,
										},
										'& > *': {
											borderRadius: `16px !important`,
										},
									}}
								/>
							</FormGroup>
						</Grid>{' '}
						<Grid item xs={12} md={6}>
							<FormGroup>
								<InputLabel
									sx={{
										color: theme.palette.secondary.main || 'black',
										fontWeight: '600',
										ml: 3,
									}}
								>
									{t('ci')}
								</InputLabel>
								<TextField
									color='secondary'
									id='ci'
									placeholder='87XXXXXXXXX'
									fullWidth={false}
									inputProps={{
										maxLength: 11,
										minLength: 11,
									}}
									margin='dense'
									name='ci'
									value={formik.values.ci}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									error={formik.touched.ci && Boolean(formik.errors.ci)}
									helperText={formik.touched.ci && formik.errors.ci}
									sx={{
										borderRadius: 4,
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: (theme) => theme.palette.primary.dark,
											borderWidth: 2,
										},
										'& .MuiInputBase-input': {
											pl: `25px !important`,
										},
										'& > *': {
											borderRadius: `16px !important`,
										},
									}}
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={12} md={6}>
							<FormGroup>
								<InputLabel
									color='secondary'
									sx={{
										color: theme.palette.secondary.main || 'black',
										fontWeight: '600',
										ml: 3,
										mb: 1,
									}}
								>
									{t('password')}
								</InputLabel>

								<OutlinedInput
									id='password'
									type={showPassword ? 'text' : 'password'}
									endAdornment={
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge='end'
											>
												{showPassword ? (
													<VisibilityOff color='secondary' />
												) : (
													<Visibility color='secondary' />
												)}
											</IconButton>
										</InputAdornment>
									}
									color='secondary'
									placeholder='********'
									value={formik.values.password}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									error={
										formik.touched.password && Boolean(formik.errors.password)
									}
									fullWidth={false}
									margin='dense'
									sx={{
										borderRadius: 4,
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: (theme) => theme.palette.primary.dark,
											borderWidth: 2,
										},
										'& .MuiInputBase-input': {
											pl: `25px !important`,
										},
										'& > *': {
											borderRadius: `16px !important`,
										},
									}}
								/>
								{formik.errors.password && formik.touched.password && (
									<FormHelperText sx={{ color: '#d32f2f' }}>
										{t(formik.errors.password)}
									</FormHelperText>
								)}
							</FormGroup>
						</Grid>
						<Grid item xs={12} md={6}>
							<FormGroup>
								<InputLabel
									color='secondary'
									sx={{
										color: theme.palette.secondary.main || 'black',
										fontWeight: '600',
										ml: 3,
										mb: 1,
									}}
								>
									{t('rePassword')}
								</InputLabel>

								<OutlinedInput
									id='rePassword'
									type={showRePassword ? 'text' : 'password'}
									color='secondary'
									endAdornment={
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												onClick={handleClickShowRePassword}
												onMouseDown={handleMouseDownPassword}
												edge='end'
											>
												{showRePassword ? (
													<VisibilityOff color='secondary' />
												) : (
													<Visibility color='secondary' />
												)}
											</IconButton>
										</InputAdornment>
									}
									placeholder='********'
									value={formik.values.rePassword}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									error={
										formik.touched.rePassword &&
										Boolean(formik.errors.rePassword)
									}
									fullWidth={false}
									margin='dense'
									sx={{
										borderRadius: 4,
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: (theme) => theme.palette.primary.dark,
											borderWidth: 2,
										},
										'& .MuiInputBase-input': {
											pl: `25px !important`,
										},
										'& > *': {
											borderRadius: `16px !important`,
										},
									}}
								/>
								{formik.errors.rePassword && formik.touched.rePassword && (
									<FormHelperText sx={{ color: '#d32f2f' }}>
										{t(formik.errors.rePassword)}
									</FormHelperText>
								)}
							</FormGroup>
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								display: 'flex',
								justifyContent: 'center',
								flexDirection: 'column',
								mt: 1,
							}}
						>
							<Typography variant='ExplicationText' textAlign='center'>
								{t('alreadyHaveAnAccount', { ns: 'common' })}
							</Typography>
							<Button
								autoFocus
								onClick={() => onOpenLogin()}
								variant='ButtonActionText'
								sx={{
									marginTop: '0px',
									pt: 0,
								}}
							>
								{t('login', { ns: 'common' })}
							</Button>
						</Grid>
						<Grid item xs={12} display='flex' justifyContent='center'>
							<LoadingButton
								fullWidth
								type='submit'
								autoFocus
								loading={loading || isLoading}
								disabled={loading || isLoading}
								loadingPosition='end'
								title={t('register')}
								variant='ButtonAcceptDialog'
								aria-disabled={isLoading || loading}
							>
								{t('register', { ns: 'common' })}
							</LoadingButton>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	);
};
