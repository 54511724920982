'use client';
import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
	Typography,
	Slider,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	FormControl,
	FormGroup,
	FormControlLabel,
	Rating,
	IconButton,
} from '@mui/material';
import { useClientTranslation } from '@negotium/common';
import { ICategoriesShow, IDictionaryCatalog } from '@negotium/models';
import { groupListCategories } from '../../app/lib/categories/actions';
import { useSearchParams } from 'next/navigation';
import { LoadingRectangle } from '../Skeleton';
import { useCatalogContext } from '../ContextCatalog';
import { getMinusAndMajorPriceProduct } from '@negotium/libCustomer';
import { formatCurrency } from 'apps/customer-portal/utils/numWithComma';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import FilterListIcon from '@mui/icons-material/FilterList';
type FilterCategoryProductProps = {
	lng: 'es' | 'en';
};

function FilterCategoryProduct({ lng }: FilterCategoryProductProps) {
	const { t } = useClientTranslation(lng, ['catalog']);
	const {
		categoriesSelected,
		handlerSelected,
		clearSelected,
		clearSelectedAndRouter,
		priceRange,
		setPriceRange,
		setExecuteSearch,
		ratings,
		setRatings,
	} = useCatalogContext();
	const [categories, setCategories] = useState<Array<ICategoriesShow>>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const searchParams = useSearchParams();
	const [expanded, setExpanded] = useState<Array<string>>(['price', 'ratings']);
	const [dictionary, setDictionary] = useState<IDictionaryCatalog | null>(null);
	const RATINGS_LIST = [5, 4, 3, 2, 1];
	const [marks, setMarks] = useState<
		Array<{
			value: number;
			label: string;
		}>
	>([]);

	const handlePriceChange = (event: Event, newValue: number | number[]) => {
		setPriceRange(newValue as number[]);
	};

	useEffect(() => {
		if (loading) {
			Promise.all([groupListCategories({}), getMinusAndMajorPriceProduct()])
				.then(([categoriesResponse, priceProductResponse]) => {
					if (categoriesResponse?.docs) {
						setCategories([...categoriesResponse.docs]);
					}
					if (priceProductResponse.data) {
						setDictionary(priceProductResponse.data);
						setPriceRange([
							priceProductResponse.data.price.min,
							priceProductResponse.data.price.max,
						]);
						setMarks([
							{
								value: priceProductResponse.data.price.min,
								label: formatCurrency(priceProductResponse.data.price.min),
							},
							{
								value: priceProductResponse.data.price.max,
								label: formatCurrency(priceProductResponse.data.price.max),
							},
						]);
					}
					setLoading(false);
				})
				.catch(() => {
					setLoading(false);
				});
		}
	}, [loading]);

	useEffect(() => {
		if (searchParams.size > 0) {
			const categoriesId: string | null = searchParams.get('categoriesId');
			if (categoriesId) {
				handlerSelected(categoriesId);
			} else {
				clearSelected();
			}
		}
	}, [searchParams.size]);

	function handlerClickClear() {
		if (searchParams.size > 0) {
			clearSelectedAndRouter();
		} else {
			clearSelected();
		}
	}

	const handleToggle = (value: string) => () => {
		handlerSelected(value);
	};

	if (loading) {
		return <LoadingRectangle width='100%' />;
	}

	if (!loading && categories.length === 0) {
		return null;
	}

	function valuetext(value: number) {
		return `${formatCurrency(value)}`;
	}

	function handlerResetRangePrice() {
		setPriceRange([
			dictionary?.price.min || 0,
			dictionary?.price.max || 999999,
		]);
		setExecuteSearch(true);
	}
	function handlerResetRating() {
		setRatings(null);
		setExecuteSearch(true);
	}

	const handleCheckboxChange = (ratingValue: number) => {
		if (ratings) {
			setRatings({ ...ratings, [ratingValue]: !ratings[ratingValue] });
		} else {
			setRatings({ [ratingValue]: true });
		}
	};

	function handleChangeExpanded(type: string) {
		if (expanded.includes(type)) {
			setExpanded([...expanded.filter((item) => item !== type)]);
		} else {
			setExpanded([...expanded, type]);
		}
	}

	function renderCategories(
		categories: Array<ICategoriesShow>,
		levelOne: boolean
	) {
		return (
			<List dense sx={{ width: '100%' }}>
				{categories.map((category) => {
					const labelId = `checkbox-list-secondary-label-${category.categoriesId}`;
					return levelOne ? (
						<ListItem key={category.categoriesId} disablePadding>
							<Accordion
								style={{
									borderRadius: 0,
									boxShadow: 'none',
								}}
								sx={{
									width: '100%',
									background: 'white',
								}}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls='panel1a-content'
									id={labelId}
									sx={{
										m: 0,
										'& .Mui-expanded ': {
											minHeight: 'auto !important',
										},
										'& .MuiAccordionSummary-content ': {
											m: 0,
										},
									}}
								>
									<Box display='flex' alignItems='center'>
										<Checkbox
											color='secondary'
											edge='end'
											onChange={handleToggle(category.categoriesId)}
											checked={categoriesSelected.includes(
												category.categoriesId
											)}
											inputProps={{ 'aria-labelledby': labelId }}
											// sx={{
											// 	color: (theme) => theme.palette.secondary?.main,
											// 	'&.Mui-checked': {
											// 		color: (theme) => theme.palette.secondary?.main,
											// 	},
											// }}
										/>

										<Typography
											variant='ExplicationTextBold'
											fontSize='14px'
											marginLeft={1}
											color='black'
											textTransform={levelOne ? 'uppercase' : 'none'}
										>
											{category.name[lng as 'es' | 'en']}
										</Typography>
									</Box>
								</AccordionSummary>
								{category.child?.length > 0 && (
									<AccordionDetails sx={{ p: 0 }}>
										<Box
											sx={{
												pl: 3,
											}}
										>
											{renderCategories(category.child, false)}
										</Box>
									</AccordionDetails>
								)}
							</Accordion>
						</ListItem>
					) : (
						<>
							<ListItem key={category.categoriesId} disablePadding>
								<ListItemButton
									sx={{
										py: 0,
									}}
								>
									<ListItemIcon>
										<Checkbox
											color='secondary'
											edge='end'
											onChange={handleToggle(category.categoriesId)}
											checked={categoriesSelected.includes(
												category.categoriesId
											)}
											inputProps={{ 'aria-labelledby': labelId }}
										/>
									</ListItemIcon>
									<ListItemText
										id={labelId}
										primary={category.name[lng as 'es' | 'en']}
										primaryTypographyProps={{
											variant: 'ExplicationText',
											color: 'black',
											fontSize: '14px',
											textTransform: levelOne ? 'uppercase' : 'none',
										}}
									/>
								</ListItemButton>
							</ListItem>
							{category.child?.length > 0 && (
								<Box
									sx={{
										pl: 5,
									}}
								>
									{renderCategories(category.child, false)}
								</Box>
							)}
						</>
					);
				})}
			</List>
		);
	}

	return (
		<Box
			sx={{
				width: '100%',
				borderRadius: 5,
			}}
		>
			<Paper
				elevation={1}
				sx={{
					width: '100%',
					background: (theme) => theme.palette.white?.main || 'white',
					borderRadius: 5,
					mb: 2,
				}}
			>
				<Box
					display='flex'
					justifyContent='space-between'
					alignItems='center'
					sx={{
						p: 1,
						px: 2,
						background: (theme) => theme.palette.primary.main,
						borderTopLeftRadius: 20,
						borderTopRightRadius: 20,
					}}
				>
					<Typography
						variant='ExplicationTextBold'
						fontSize='1.2rem'
						color='white.main'
					>
						{t('filterByCategory')}
					</Typography>
					<IconButton
						title={t('clearFilterCategory')}
						disabled={categoriesSelected.length === 0}
						onClick={() => handlerClickClear()}
					>
						<FilterListIcon
							fontSize='large'
							sx={{
								color: 'white !important',
							}}
						/>
					</IconButton>
				</Box>
				<Box
					sx={{
						p: 1,
						pb: 2,
						maxHeight: 'calc(55vh - 10px)',
						overflow: 'scroll',
					}}
				>
					{!loading &&
						categories &&
						categories.length > 0 &&
						renderCategories(categories, true)}
					{loading &&
						[...Array(3).keys()].map(() => (
							<Box marginTop={1} display='flex' justifyContent='space-between'>
								<LoadingRectangle width={'80%'} height='20px' />
								<LoadingRectangle width={'10%'} height='20px' />
							</Box>
						))}
				</Box>
			</Paper>
			{dictionary?.price && (
				<Accordion
					style={{
						borderRadius: 20,
					}}
					expanded={expanded.includes('price')}
					onChange={() => handleChangeExpanded('price')}
					sx={{
						width: '100%',
						background: (theme) => theme.palette.white?.main || 'white',
						mb: 1,
						'&::before': {
							display: 'none',
						},
					}}
				>
					<AccordionSummary
						expandIcon={
							<ExpandMoreIcon
								sx={{
									color: (theme) => theme.palette.white?.main || 'white',
								}}
							/>
						}
						aria-controls='panel-price-range'
						id='panel-price-range'
						sx={{
							width: '100%',
							background: (theme) => theme.palette.primary.main,
							borderTopLeftRadius: 20,
							borderTopRightRadius: 20,
						}}
					>
						<Typography
							variant='ExplicationTextBold'
							fontSize='1.2rem'
							color='white.main'
						>
							{`${t('price')}`}
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						sx={{
							border: '20px !important',
						}}
					>
						<Box
							sx={{
								pt: 4,
								pb: 2,
								px: 5,
							}}
						>
							<Slider
								color='primary'
								value={priceRange}
								onChange={handlePriceChange}
								valueLabelDisplay='auto'
								min={dictionary.price.min}
								max={dictionary.price.max}
								valueLabelFormat={(value) => `${formatCurrency(value)}`}
								getAriaValueText={valuetext}
								aria-labelledby='price-slider'
								marks={marks}
							/>
						</Box>
						<Box
							display='flex'
							justifyContent='space-evenly'
							alignItems='center'
							sx={{
								p: 1,
							}}
						>
							<Button
								color='warning'
								size='medium'
								variant='contained'
								title={t('search')}
								// disabled={processing || isProcessing}
								onClick={() => handlerResetRangePrice()}
							>
								{t('clean')}
							</Button>
							<Button
								color='secondary'
								size='medium'
								variant='contained'
								title={t('search')}
								// disabled={processing || isProcessing}
								onClick={() => setExecuteSearch(true)}
							>
								{t('search')}
							</Button>
						</Box>
					</AccordionDetails>
				</Accordion>
			)}
			<Accordion
				style={{
					borderRadius: 20,
				}}
				expanded={expanded.includes('ratings')}
				onChange={() => handleChangeExpanded('ratings')}
				sx={{
					width: '100%',
					background: (theme) => theme.palette.white?.main || 'white',
					'&::before': {
						display: 'none',
					},
				}}
			>
				<AccordionSummary
					expandIcon={
						<ExpandMoreIcon
							sx={{
								color: (theme) => theme.palette.white?.main || 'white',
							}}
						/>
					}
					aria-controls='panel-rating-header'
					id='panel-rating-header'
					sx={{
						width: '100%',
						background: (theme) => theme.palette.primary?.main,
						borderTopLeftRadius: 20,
						borderTopRightRadius: 20,
					}}
				>
					<Typography
						variant='ExplicationTextBold'
						fontSize='1.2rem'
						color='white.main'
					>
						{`${t('score')}`}
					</Typography>
				</AccordionSummary>
				<AccordionDetails
					sx={{
						borderBottomLeftRadius: '20px !important',
						borderBottomRightRadius: '20px !important',
					}}
				>
					<Box
						sx={{
							pt: 2,
							pb: 2,
							px: 5,
						}}
					>
						<FormControl component='fieldset'>
							<FormGroup row>
								{RATINGS_LIST.map((ratingValue) => (
									<FormControlLabel
										key={ratingValue}
										control={
											<Checkbox
												checked={ratings?.[Number(ratingValue)] as boolean}
												onChange={() =>
													handleCheckboxChange(Number(ratingValue))
												}
												value={ratingValue}
											/>
										}
										label={
											<Rating
												value={Number(ratingValue)}
												size='medium'
												readOnly
												icon={
													<StarIcon
														fontSize='inherit'
														sx={{
															color: (theme) => theme.palette.primary.main,
														}}
													/>
												}
												emptyIcon={
													<StarBorderIcon
														fontSize='inherit'
														sx={{
															color: (theme) => theme.palette.primary.main,
														}}
													/>
												}
											/>
										}
									/>
								))}
							</FormGroup>
						</FormControl>
					</Box>
					<Box
						display='flex'
						justifyContent='space-evenly'
						alignItems='center'
						sx={{
							p: 1,
						}}
					>
						<Button
							color='warning'
							size='medium'
							variant='contained'
							title={t('search')}
							// disabled={processing || isProcessing}
							onClick={() => handlerResetRating()}
						>
							{t('clean')}
						</Button>
						<Button
							color='secondary'
							size='medium'
							variant='contained'
							title={t('search')}
							// disabled={processing || isProcessing}
							onClick={() => setExecuteSearch(true)}
						>
							{t('search')}
						</Button>
					</Box>
				</AccordionDetails>
			</Accordion>
		</Box>
	);
}
export default FilterCategoryProduct;
