'use client';
import React, { MouseEvent, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Image from 'next/image';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { signOut } from '../../app/lib/auth/actions';
import { Drawer, List, ListItem, ListItemButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Link from 'next/link';
import { MenuLink } from '@negotium/models';
import { useClientTranslation, useSessionClient } from '@negotium/common';
import { pages } from '../../utils/constant';
import { useNotification } from '../ErrorContext';
import { useContextGeneral } from '../ContextGeneral';
import { Login } from '../Login';
import { Register } from '../Register';
import { ForgetPassword } from '../ForgetPassword';
import { ChangePasswordDialog } from '../ChangePassword';
import { ActivateAccount } from '../ActivateAccount';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const settingsLogout: Array<MenuLink> = [
	{ label: 'Iniciar sesión', pathname: '/signIn', validPermission: 'login' },
	{ label: 'Registrarse', pathname: '/signUp', validPermission: 'login' },
];
const settingsAuth: Array<MenuLink> = [
	{ label: 'Mi cuenta', pathname: '/account', validPermission: 'login' },
];

function Navbar({ lng }: { lng: string }) {
	const router = useRouter();
	const pathname = usePathname();

	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
	const { t } = useClientTranslation(lng, ['common']);
	const user = useSessionClient();
	const { setErrorNotification, setSuccessNotification } = useNotification();
	const searchParams = useSearchParams();
	const [open, setOpen] = useState<boolean>(false);

	const { showLogin: openLogin, setShowLogin: setOpenLogin } =
		useContextGeneral();
	const [openRegister, setOpenRegister] = useState<boolean>(false);
	const [openForgetPassword, setOpenForgetPassword] = useState<boolean>(false);
	const [openActivateAccount, setOpenActivateAccount] = useState<null | {
		email: string;
		code: string;
	}>(null);
	const [openChangePassword, setOpenChangePassword] = useState<null | {
		email: string;
		code: string;
	}>(null);

	const toggleDrawer = (newOpen: boolean) => () => {
		setOpen(newOpen);
	};

	const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};
	const handleLogout = async () => {
		signOut()
			.then(() => {
				setSuccessNotification(t('sessionClosedSuccessfully'));
				router.push(`/${lng}`);
			})
			.catch(() => {
				setErrorNotification(t('sessionClosedError'));
			});
		handleCloseUserMenu();
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	useEffect(() => {
		const isActivateAccount: string | null =
			searchParams.get('activateAccount');
		if (isActivateAccount) {
			const code: string | null = searchParams.get('code');
			const email: string | null = searchParams.get('email');
			setOpenActivateAccount({
				email: email || '',
				code: code || '',
			});
		}
		const isChangePassword: string | null = searchParams.get('changePassword');
		if (isChangePassword) {
			const code: string | null = searchParams.get('code');
			const email: string | null = searchParams.get('email');
			setOpenChangePassword({
				email: email || '',
				code: code || '',
			});
		}
	}, [searchParams.size]);

	const isVisible = (permissions: string) => {
		return user?.permissions.includes(permissions);
	};

	function renderMenuMobile() {
		return (
			<Box sx={{ display: { xs: 'flex', md: 'none' } }}>
				<Button onClick={toggleDrawer(true)}>
					<MenuIcon
						fontSize='large'
						sx={{
							color: (theme) => theme.palette.white?.main || 'white',
						}}
					/>
				</Button>
				<Drawer
					open={open}
					onClose={toggleDrawer(false)}
					sx={{
						zIndex: 99999,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
					PaperProps={{
						sx: {
							width: '80%',
							background: (theme) => theme.palette.secondary.main,
						},
					}}
				>
					<Box>
						<Box display='flex' justifyContent='flex-end' marginTop='10px'>
							<Button onClick={toggleDrawer(false)}>
								<CloseIcon
									fontSize='large'
									sx={{
										color: (theme) => theme.palette.white?.main || 'white',
									}}
								/>
							</Button>
						</Box>

						<List
							sx={{
								width: '100%',
							}}
						>
							{pages.map((page) => (
								<ListItem
									onClick={toggleDrawer(false)}
									key={page.pathname}
									alignItems='center'
									sx={{
										width: '100%',
										justifyContent: { md: 'flex-end', xs: 'center' },
										height: '45px',
									}}
								>
									<Link
										href={page.pathname}
										style={{
											textDecoration: 'none',
										}}
									>
										<Typography
											variant='BodySection'
											fontSize='clamp(0.8rem,3vw,1.2rem)'
											textAlign={{ md: 'right', xs: 'center' }}
											textTransform='uppercase'
											color={(theme) => theme.palette.white?.main || 'white'}
										>
											{t(page.label)}
										</Typography>
									</Link>
								</ListItem>
							))}
							<ListItem>
								<Button
									title={user ? t('profile') : t('registerOrLogin')}
									onClick={
										user
											? () => {
													router.push('/profile');
													setOpen(false);
											  }
											: () => {
													setOpenLogin(true);
													setOpen(false);
											  }
									}
									variant='ButtonNavbarAction'
									startIcon={<AccountCircleIcon />}
									sx={{
										width: '100%',

										color: (theme) => theme.palette.white?.main || 'white',
									}}
								>
									{user ? t('profile') : t('registerOrLogin')}
								</Button>
							</ListItem>{' '}
							{isVisible('adminPanel') && (
								<ListItem>
									<Button
										title={t('admin')}
										onClick={() =>
											router.push(
												`${process.env.NEXT_PUBLIC_DOMAIN_ADMIN}/${lng}/admin`
											)
										}
										variant='ButtonNavbarAction'
										startIcon={<AdminPanelSettingsIcon />}
										sx={{
											width: '100%',

											color: (theme) => theme.palette.white?.main || 'white',
										}}
									>
										{t('admin')}
									</Button>
								</ListItem>
							)}
						</List>
					</Box>

					<Box
						sx={{
							width: { xs: '70%', md: '25%' },
							padding: '5px 0px',
							margin: '50px auto 0 auto',
						}}
					>
						<Link href={`/${lng}`} hrefLang={lng}>
							<Image
								src='/static/lacuadra-lacuadra-logo-white667x218.png'
								width={667}
								height={218}
								alt={process.env.NEXT_PUBLIC_NAME || ''}
								style={{ width: '100%', height: 'auto' }}
							/>
						</Link>
					</Box>
				</Drawer>
			</Box>
		);
	}

	function renderMenuDesktop() {
		return (
			<Box
				display='flex'
				justifyContent='space-around'
				alignItems='center'
				sx={{
					display: { xs: 'none', md: 'flex' },
				}}
			>
				<List
					sx={{
						display: 'flex',
					}}
				>
					{pages.map((page) => (
						<Link
							href={`${page.pathname}`}
							key={page.pathname}
							style={{
								textDecoration: 'none',
							}}
						>
							<ListItemButton
								className='ListItemButton'
								selected={page.pathname === pathname}
							>
								{t(page.label)}
							</ListItemButton>
						</Link>
					))}
				</List>
				<Box sx={{ flexGrow: 0, alignItems: 'center' }}>
					<Button
						title={user ? t('menuUser') : t('registerOrLogin')}
						onClick={
							user ? (e) => handleOpenUserMenu(e) : () => setOpenLogin(true)
						}
						variant='ButtonNavbarAction'
					>
						<AccountCircleIcon />
					</Button>

					<Menu
						sx={{ mt: '45px' }}
						id='menu-appbar'
						anchorEl={anchorElUser}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={Boolean(anchorElUser)}
						onClose={handleCloseUserMenu}
					>
						{isVisible('adminPanel') && (
							<MenuItem
								onClick={() => {
									router.push(
										`${process.env.NEXT_PUBLIC_DOMAIN_ADMIN}/backoffice/es`
									);
									handleCloseUserMenu();
								}}
							>
								<Typography textAlign='center'>
									{t('admin', { ns: 'common' })}
								</Typography>
							</MenuItem>
						)}
						{(user === null ? settingsLogout : settingsAuth).map((setting) => {
							return (
								<MenuItem
									key={setting.pathname}
									onClick={() => {
										router.push(setting.pathname);
										handleCloseUserMenu();
									}}
								>
									<Typography textAlign='center'>{setting.label}</Typography>
								</MenuItem>
							);
						})}
						{user ? (
							<MenuItem
								onClick={() => {
									handleLogout();
									handleCloseUserMenu();
								}}
							>
								<Typography textAlign='center'>
									{t('logout', { ns: 'common' })}
								</Typography>
							</MenuItem>
						) : null}
					</Menu>
				</Box>
			</Box>
		);
	}

	return (
		<AppBar
			position='static'
			sx={{
				minHeight: '8vh',
				background: (theme) => theme.palette.secondary?.main,
			}}
		>
			<Container maxWidth='xl'>
				<Toolbar
					disableGutters
					sx={{
						justifyContent: 'space-between',
						height: '100%',
						display: 'flex',
						justifyItems: 'center',
					}}
				>
					<Box
						sx={{
							width: { xs: '50%', md: '10%' },
							minHeight: '8vh',
							display: 'flex',
							alignItems: 'center',
							position: 'relative',
						}}
					>
						<Link
							href={`/${lng}`}
							hrefLang={lng}
							style={{
								height: '100%',
								width: '100%',
							}}
						>
							<Image
								src='/static/lacuadra-logo-white667x218.png'
								width={667}
								height={128}
								alt={process.env.NEXT_PUBLIC_NAME || ''}
								style={{ width: '100%', height: 'auto' }}
							/>
						</Link>
					</Box>

					{renderMenuMobile()}

					{renderMenuDesktop()}
				</Toolbar>
			</Container>
			<Login
				open={openLogin}
				onClose={() => setOpenLogin(false)}
				lng={lng as 'es' | 'en'}
				onOpenRegister={() => {
					setOpenRegister(true);
					setOpenLogin(false);
				}}
				onOpenForgetPassword={() => {
					setOpenForgetPassword(true);
					setOpenLogin(false);
				}}
			/>
			<Register
				open={openRegister}
				onClose={() => setOpenRegister(false)}
				lng={lng as 'es' | 'en'}
				onOpenLogin={() => {
					setOpenLogin(true);
					setOpenRegister(false);
				}}
				onOpenActivateAccount={(email) => {
					setOpenActivateAccount({
						email: email || '',
						code: '',
					});
				}}
			/>
			<ForgetPassword
				onOpenChangePassword={(email) => {
					setOpenForgetPassword(false);
					setOpenChangePassword({
						email: email || '',
						code: '',
					});
				}}
				onOpenLogin={() => {
					setOpenLogin(true);
					setOpenForgetPassword(false);
				}}
				open={openForgetPassword}
				onClose={() => setOpenForgetPassword(false)}
				lng={lng as 'es' | 'en'}
			/>
			<ChangePasswordDialog
				open={!!openChangePassword}
				onClose={() => {
					router.push('/');
					setOpenChangePassword(null);
				}}
				lng={lng as 'es' | 'en'}
				info={openChangePassword}
				onOpenLogin={() => {
					setOpenLogin(true);
					setOpenChangePassword(null);
				}}
			/>{' '}
			<ActivateAccount
				open={!!openActivateAccount}
				onClose={() => {
					router.push('/');
					setOpenActivateAccount(null);
				}}
				lng={lng as 'es' | 'en'}
				onOpenLogin={() => {
					setOpenLogin(true);
					setOpenActivateAccount(null);
				}}
				info={openActivateAccount}
			/>{' '}
		</AppBar>
	);
}
export default Navbar;
