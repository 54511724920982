'use client';
import {
	Alert,
	AlertTitle,
	Box,
	Chip,
	Container,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Paper,
	Rating,
	Typography,
} from '@mui/material';
import { useClientTranslation, useSessionClient } from '@negotium/common';
import React, { useEffect, useState } from 'react';
import { useNotification } from '../ErrorContext';
import { LoadingRectangle } from '../Skeleton';
import {
	IImageShow,
	IProductCatalog,
	IProductCatalogDetails,
} from '@negotium/models';
import {
	addProductCartShop,
	addUserRateToProduct,
	addUserToWhishList,
	getProductDetails,
	getRelationsProductsByCategories,
} from '@negotium/libCustomer';
import { ImageWithFallback } from '../ImageWithFallback';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useContextCartShop } from '../ContextCartShop';
import { CardProduct } from '../CardProduct';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { formatCurrency } from 'apps/customer-portal/utils/numWithComma';
import { ButtonAddProductCardShop } from '../ButtonAddProductCardShop';
import { CardProductHorizontal } from '../CardProductHorizontal';
import { useContextGeneral } from '../ContextGeneral';

type ProductDetailsProps = {
	lng: 'es' | 'en';
	productId: string;
};

export const ProductDetails = ({ lng, productId }: ProductDetailsProps) => {
	const { t } = useClientTranslation(lng, ['products', 'catalog']);
	const [productDetails, setProductDetails] =
		useState<IProductCatalogDetails | null>(null);

	const { changeCountProducts, cartShopId, saveCartShopIdLocalStorage } =
		useContextCartShop();
	const [imageSelected, setImageSelected] = useState<IImageShow | null>(null);
	const [products, setProducts] = useState<Array<IProductCatalog>>([]);
	const user = useSessionClient();
	const { setShowLogin } = useContextGeneral();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { setSuccessNotification, setErrorNotification } = useNotification();

	useEffect(() => {
		if (productId && isLoading) {
			getProductDetails(productId)
				.then((result) => {
					setProductDetails(result.data);
					setImageSelected(result.data?.productImage || null);
					setIsLoading(false);
				})
				.catch(() => {
					setErrorNotification(t('errorToGetProduct'));
					setIsLoading(false);
				});
		}
	}, [productId, isLoading]);

	useEffect(() => {
		if (
			productDetails?.productCategories &&
			productDetails?.productCategories?.length > 0
		) {
			getRelationsProductsByCategories(
				productDetails.productCategories,
				productId
			)
				.then((result) => {
					setProducts(result.docs);
				})
				.catch(() => {
					setErrorNotification(t('errorToGetProductRelationsByCategories'));
				});
		}
	}, [productDetails?.productCategories]);

	function handlerAddProduct(storageUniqueId: string, amount: number) {
		addProductCartShop({ amount, storageUniqueId, cartId: cartShopId })
			.then((response) => {
				changeCountProducts();
				saveCartShopIdLocalStorage(response.data as string);
				setSuccessNotification(
					t('addedProductToCartShopSuccessfully', { ns: 'catalog' })
				);
			})
			.catch(() => {
				setErrorNotification(
					t('errorToAddedProductToCartShop', { ns: 'catalog' })
				);
			});
	}

	function handleSave(amount: number) {
		if (productDetails) {
			handlerAddProduct(productDetails?.storageUniqueId, amount);
		}
	}
	function addOrRemoveFavorite(storageUniqueId: string) {
		if (user) {
			addUserToWhishList(storageUniqueId)
				.then((result) => {
					setIsLoading(true);
					setSuccessNotification(
						t(
							result?.data
								? 'successToProductAddedWishList'
								: 'successToProductRemoveWishList'
						)
					);
				})
				.catch(() => {
					setErrorNotification(t('errorToProductAddedWishList'));
				});
		} else {
			setShowLogin(true);
		}
	}

	function setWishListUserLogged(data: boolean, index: number) {
		const aux = products;
		aux[index] = {
			...aux[index],
			whishListUserLogged: data,
		};
		setProducts([...aux]);
	}

	function onChange(newValue: number, storageUniqueId: string) {
		if (user) {
			addUserRateToProduct(storageUniqueId, newValue)
				.then((result) => {
					console.log('🚀 ~ .then ~ result:', result);
					if (result.data) {
						setSuccessNotification(t('thankForRateUs'));
					}
				})
				.catch(() => {
					setErrorNotification(t('errorToRateProduct'));
				});
		} else {
			setShowLogin(true);
		}
	}

	if (isLoading) {
		return (
			<Container
				maxWidth='xl'
				sx={{
					my: 3,
				}}
			>
				<Grid container spacing={1} marginTop={0.5}>
					<Grid item xs={12}>
						<LoadingRectangle height={'50vh'} width={'100%'} />
					</Grid>
				</Grid>
			</Container>
		);
	}

	if (!productDetails) {
		return (
			<Container
				maxWidth='xl'
				sx={{
					my: 3,
				}}
			>
				<Grid container>
					<Grid item xs={12}>
						<Alert severity='info'>
							<AlertTitle>{t('productNotFound')}</AlertTitle>
						</Alert>
					</Grid>
				</Grid>
			</Container>
		);
	}

	return (
		<Container
			maxWidth={false}
			disableGutters
			sx={{
				my: 5,
			}}
		>
			<Grid container spacing={5}>
				<Grid item xs={12}>
					<Container maxWidth='xl'>
						<Paper
							elevation={4}
							sx={{
								p: { md: 3, xs: 1 },
								borderRadius: 5,
								background: (theme) => theme.palette.white?.main,
								boxShadow: {
									md: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
									xs: 'none',
								},
							}}
						>
							<Grid container spacing={2}>
								<Grid item xs={12} md={4}>
									<Box
										sx={{
											background: (theme) =>
												theme.palette.white?.main || '#ffffff',
											borderRadius: 5,
											overflow: 'hidden',
										}}
									>
										<Box
											width={{ xs: '100%', md: '100%' }}
											sx={{
												borderRadius: 5,
												display: 'flex',
											}}
										>
											<ImageWithFallback
												src={imageSelected?.url || ''}
												fallbackImage='/static/facebook-lacuadra-600x315.png'
												alt={productDetails?.productName[lng] || ''}
												width={imageSelected?.width || 100}
												height={imageSelected?.height || 100}
												style={{
													width: '100%',
													height: '400px',
													display: 'flex',
												}}
											/>
										</Box>
									</Box>

									<Box display='flex'>
										{productDetails?.productImageGallery.map((image) => {
											return (
												<Box
													key={image.url}
													sx={{
														mx: 1,
														my: 1,
														width: 100,
														height: 100,
														backgroundImage: `url(${image.url})`,
														backgroundSize: 'cover',
														backgroundPosition: 'center',
														cursor: 'pointer',
														background: (theme) =>
															theme.palette.white?.main || '#ffffff',
														borderRadius: 5,
														overflow: 'hidden',
														boxShadow:
															'0px 0px 10px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 7px 14px rgba(0, 0, 0, 0.1)',
													}}
													onClick={() => setImageSelected(image)}
												>
													<ImageWithFallback
														src={image?.url || ''}
														fallbackImage='/static/facebook-lacuadra-600x315.png'
														alt={image?.url || ''}
														width={image?.width || 100}
														height={image?.height || 100}
														style={{ width: '100%', height: '100%' }}
													/>
												</Box>
											);
										})}
									</Box>
								</Grid>
								<Grid
									item
									xs={12}
									md={8}
									sx={{
										px: { md: '30px !important', xs: '10px' },
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'space-between',
									}}
								>
									<Box>
										<Box
											display='flex'
											justifyContent='space-between'
											alignItems='center'
											mb={3}
										>
											<Rating
												value={productDetails.ratingAverage}
												size='large'
												onChange={(e, newValue) => {
													onChange(
														newValue || 0,
														productDetails.storageUniqueId
													);
												}}
												icon={
													<StarIcon
														fontSize='inherit'
														sx={{
															color: (theme) => theme.palette.secondary.main,
														}}
													/>
												}
												emptyIcon={
													<StarBorderIcon
														fontSize='inherit'
														sx={{
															color: (theme) => theme.palette.secondary.main,
														}}
													/>
												}
											/>
											<IconButton
												aria-label={t('addToWishList')}
												color='secondary'
												size='large'
												title={t('addToWishList')}
												onClick={() =>
													addOrRemoveFavorite(
														productDetails?.storageUniqueId || ''
													)
												}
											>
												{productDetails?.whishListUserLogged ? (
													<FavoriteIcon fontSize='large' />
												) : (
													<FavoriteBorderIcon fontSize='large' />
												)}
											</IconButton>
										</Box>
										<Box
											display='flex'
											justifyContent='space-between'
											alignItems='center'
											flexDirection={{ xs: 'column', md: 'row' }}
										>
											<Typography
												variant='ExplicationText'
												fontSize='clamp(1rem,3vw,1.5rem)'
												fontWeight='300'
											>
												{productDetails?.productName[lng] || ''}
											</Typography>
											{productDetails?.productTag[lng] && (
												<Box display='flex' justifyContent='flex-end'>
													<Chip
														label={productDetails?.productTag[lng]}
														color='success'
														variant='filled'
													/>
												</Box>
											)}
										</Box>
										<Box my={2}>
											<Typography
												variant='BodySection'
												fontSize='1rem'
												letterSpacing={1}
												fontWeight='600'
												sx={{ lineHeight: '1rem' }}
											>
												{`${formatCurrency(
													productDetails.productSalePrice,
													productDetails.productCurrency
												)} ${productDetails.productCurrency}`}
											</Typography>
										</Box>
										<Box>
											<Divider
												sx={{
													borderColor: (theme) => theme.palette.secondary.main,
												}}
											/>
										</Box>
										<Box my={2}>
											<Typography
												variant='ExplicationText'
												fontWeight='300'
												fontSize='clamp(0.7rem,3vw,1.2rem)'
											>
												{productDetails?.productDetails[lng] || ''}
											</Typography>
											<List
												sx={{
													display: 'flex',
													flexWrap: 'wrap',
													width: '100%',
												}}
											>
												{productDetails.attributes?.map((attribute) => {
													return (
														<ListItem
															key={attribute.attributeId}
															sx={{
																pl: 0,
																width: 'max-content',
															}}
														>
															<ListItemText
																sx={{
																	display: 'flex',
																	alignItems: 'flex-start',
																}}
																primary={
																	attribute.attributeName[lng]
																		? `${attribute.attributeName[lng]}: `
																		: ''
																}
																primaryTypographyProps={{
																	fontWeight: '600',
																	mr: '3px',
																	mt: 0,
																	fontSize: '14px',
																	variant: 'body1',
																}}
																secondaryTypographyProps={{
																	mr: '3px',
																	mt: 0,
																	fontSize: '14px',
																	variant: 'body1',
																}}
																secondary={attribute.attributeValue?.join(', ')}
															/>
														</ListItem>
													);
												})}
											</List>
										</Box>
										<Box>
											<Divider
												sx={{
													borderColor: (theme) => theme.palette.secondary.main,
												}}
											/>
										</Box>
									</Box>

									<Box
										sx={{
											display: 'flex',
											justifyContent: 'flex-end',
											alignItems: 'center',
											mt: 3,
										}}
									>
										<ButtonAddProductCardShop
											lng={lng}
											onCallback={handleSave}
											justifyContent='flex-end'
										/>
									</Box>
								</Grid>
							</Grid>
						</Paper>
					</Container>
				</Grid>
				{products.length > 0 && (
					<Grid item xs={12}>
						<Container
							maxWidth={false}
							sx={{
								background: (theme) => theme.palette.primary.light,
								mt: 3,
								pt: 5,
								pb: 7,
							}}
						>
							<Container maxWidth='xl'>
								<Grid container spacing={3}>
									<Grid item xs={12}>
										<Typography variant='TitleSection' mb={2}>
											{t('relationsProducts')}
										</Typography>
									</Grid>

									{products.map((product, index) => (
										<Grid
											item
											xs={12}
											sm={4}
											md={3}
											lg={2.3}
											key={product.productId}
											sx={{
												minHeight: '100%',
											}}
										>
											<Box
												sx={{
													display: {
														md: 'block',
														xs: 'none',
													},
												}}
											>
												<CardProduct
													product={product}
													lng={lng}
													onAddProduct={handlerAddProduct}
													setWishListUserLogged={(data) =>
														setWishListUserLogged(data, index)
													}
												/>
											</Box>
											<Box
												sx={{
													display: {
														xs: 'block',
														md: 'none',
													},
												}}
											>
												<CardProductHorizontal
													product={product}
													lng={lng}
													onAddProduct={handlerAddProduct}
													setWishListUserLogged={(data) =>
														setWishListUserLogged(data, index)
													}
												/>
											</Box>
										</Grid>
									))}
								</Grid>
							</Container>
						</Container>
					</Grid>
				)}
			</Grid>
		</Container>
	);
};
