'use client';
import {
	Box,
	Card,
	CardActions,
	CardContent,
	Chip,
	IconButton,
	Rating,
	Typography,
} from '@mui/material';
import { IProductCatalog } from '@negotium/models';
import React, { useEffect, useState } from 'react';
import { ImageWithFallback } from '../ImageWithFallback';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useClientTranslation, useSessionClient } from '@negotium/common';
import {
	addUserRateToProduct,
	addUserToWhishList,
} from 'apps/customer-portal/app/lib/product/actions';
import { useNotification } from '../ErrorContext';
import Link from 'next/link';
import { formatCurrency } from 'apps/customer-portal/utils/numWithComma';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { ButtonAddProductCardShop } from '../ButtonAddProductCardShop';
import { useContextGeneral } from '../ContextGeneral';

type CardProductProps = {
	product: IProductCatalog;
	lng: 'es' | 'en';
	setWishListUserLogged: (data: boolean) => void;
	onAddProduct?: (storageUniqueId: string, amount: number) => void;
};

export const CardProduct = ({
	product,
	lng,
	onAddProduct,
	setWishListUserLogged,
}: CardProductProps) => {
	const { t } = useClientTranslation(lng, ['catalog']);
	const { setErrorNotification, setSuccessNotification } = useNotification();
	const [rating, setRating] = useState(4);
	const user = useSessionClient();
	const { setShowLogin } = useContextGeneral();

	useEffect(() => {
		if (product?.ratingAverage) {
			setRating(product?.ratingAverage);
		}
	}, [product?.ratingAverage]);

	function onChange(newValue: number, storageUniqueId: string) {
		if (user) {
			addUserRateToProduct(storageUniqueId, newValue)
				.then((result) => {
					if (result.data) {
						setRating(result.data);
						setSuccessNotification(t('thankForRateUs'));
					} else {
						setRating(newValue);
					}
				})
				.catch(() => {
					setErrorNotification(t('errorToRateProduct'));
				});
		} else {
			setShowLogin(true);
		}
	}

	function handleSave(value: number) {
		if (product && onAddProduct) {
			onAddProduct(product?.storageUniqueId, value);
		}
	}
	function addOrRemoveFavorite(storageUniqueId: string) {
		if (user) {
			addUserToWhishList(storageUniqueId)
				.then((result) => {
					setWishListUserLogged(result?.data || false);
					setSuccessNotification(
						t(
							result?.data
								? 'successToProductAddedWishList'
								: 'successToProductRemoveWishList'
						)
					);
				})
				.catch(() => {
					setErrorNotification(t('errorToProductAddedWishList'));
				});
		} else {
			setShowLogin(true);
		}
	}

	return (
		<Card
			sx={{
				background: 'white',
				borderRadius: 5,
				minHeight: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				position: 'relative',
				boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.4)',
			}}
		>
			<Box width={{ xs: '100%', md: '100%' }} position='relative'>
				<ImageWithFallback
					src={product.productImage?.url || ''}
					fallbackImage='/static/facebook-lacuadra-600x315.png'
					alt={product.productName[lng]}
					width={product.productImage?.width}
					height={product.productImage?.height}
					style={{ width: '100%', height: 'auto' }}
				/>
				<Box
					sx={{
						position: 'absolute',
						bottom: '20px',
						left: '10px',
					}}
				>
					<Rating
						value={rating}
						size='small'
						onChange={(e, newValue) => {
							onChange(newValue || 0, product.storageUniqueId);
						}}
						icon={
							<StarIcon
								fontSize='inherit'
								sx={{
									color: (theme) => theme.palette.secondary.main,
								}}
							/>
						}
						emptyIcon={
							<StarBorderIcon
								fontSize='inherit'
								sx={{
									color: (theme) => theme.palette.secondary.main,
								}}
							/>
						}
					/>
				</Box>
				<Box
					sx={{
						position: 'absolute',
						top: '10px',
						right: '10px',
					}}
				>
					<IconButton
						aria-label={t('addToWishList')}
						color='primary'
						size='large'
						title={t('addToWishList')}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							addOrRemoveFavorite(product.storageUniqueId);
						}}
					>
						{product?.whishListUserLogged ? (
							<FavoriteIcon
								fontSize='medium'
								sx={{
									color: (theme) => theme.palette.secondary.main,
								}}
							/>
						) : (
							<FavoriteBorderIcon
								fontSize='medium'
								sx={{
									color: (theme) => theme.palette.secondary.main,
								}}
							/>
						)}
					</IconButton>
				</Box>
			</Box>

			<CardContent sx={{ paddingBottom: '0px !important' }}>
				<Link
					href={`/product/${product.productId}`}
					style={{
						textDecoration: 'none',
						color: 'unset',
					}}
				>
					<Typography
						variant='ExplicationText'
						fontSize='1rem'
						fontWeight='500'
					>
						{product.productName[lng]}
					</Typography>{' '}
				</Link>
				<Typography
					variant='BodySection'
					fontSize='1rem'
					fontWeight='600'
					sx={{ lineHeight: '1rem' }}
				>
					{`${formatCurrency(
						product.productSalePrice,
						product.productCurrency
					)} ${product.productCurrency}`}
				</Typography>
				{product.productTags[lng] && (
					<Box
						display='flex'
						justifyContent='flex-end'
						position='absolute'
						top='2%'
						right='2%'
					>
						<Chip
							label={product.productTags[lng]}
							color='success'
							variant='filled'
						/>
					</Box>
				)}
			</CardContent>

			<CardActions
				disableSpacing
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					p: 2,
				}}
			>
				<ButtonAddProductCardShop lng={lng} onCallback={handleSave} />
			</CardActions>
		</Card>
	);
};
