'use client';
import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useClientTranslation } from '@negotium/common';
import { IAdvertisingShow } from '@negotium/models';
import { useNotification } from '../ErrorContext';
import { getAdvertisementsActives } from '@negotium/libCustomer';
import dynamic from 'next/dynamic';
import Slider from 'react-slick';
import { LoadingRectangle } from '../Skeleton';
import CardAdvertising from '../CardCategory copy';

type AdvertisingProps = {
	lng: 'es' | 'en';
};

const ArrowSlider = dynamic(
	() =>
		import('@negotium/componentsCustomer').then((module) => module.ArrowSlider),
	{ ssr: false }
);

export const Advertising = ({ lng }: AdvertisingProps) => {
	const { t } = useClientTranslation(lng, ['common']);

	const [advertisements, setAdvertisements] = useState<Array<IAdvertisingShow>>(
		[]
	);

	const [loadingAdvertisements, setLoadingAdvertisements] =
		useState<boolean>(true);
	const { setErrorNotification } = useNotification();

	useEffect(() => {
		if (loadingAdvertisements) {
			getAdvertisementsActives()
				.then((response) => {
					setAdvertisements(response.docs);
					setLoadingAdvertisements(false);
				})
				.catch(() => {
					setLoadingAdvertisements(false);
					setErrorNotification(t('errorGetAdvertisements'));
				});
		}
	}, [loadingAdvertisements]);

	if (loadingAdvertisements) {
		return (
			<Container
				maxWidth={false}
				sx={{
					py: 4,
					background: (theme) => theme.palette.primary.light,
				}}
			>
				<Container maxWidth='xl'>
					<Grid container spacing={2}>
						<Grid item xs={12} md={12}>
							<LoadingRectangle width={'20%'} height='5vh' />
						</Grid>
						{[...Array(3).keys()].map((item) => (
							<Grid item xs={12} md={6} key={item}>
								<LoadingRectangle width={'100%'} />
							</Grid>
						))}
					</Grid>
				</Container>
			</Container>
		);
	}

	if (advertisements.length === 0) {
		return null;
	}

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		autoPlay: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: '60px',
		arrows: true,
		nextArrow: <ArrowSlider right={true} />,
		prevArrow: <ArrowSlider right={false} />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
					dots: false,
				},
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: false,
				},
			},
		],
	};

	return (
		<Container
			maxWidth={false}
			sx={{
				py: 4,
				background: (theme) => theme.palette.primary.light,
				minHeight: '25vh',
			}}
		>
			<Container maxWidth='xl' sx={{ mb: 2 }}>
				<Grid container>
					<Grid item xs={12}>
						<Typography variant='TitleSection' color='black' my={2}>
							{t('weCanOffer')}
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sx={{
							py: 4,
						}}
					>
						<Slider {...settings}>
							{[...advertisements].map((advertising, index) => {
								return (
									<Box
										key={advertising.advertisingId}
										sx={{
											paddingBottom: '50px',
										}}
									>
										<CardAdvertising lng={lng} advertising={advertising} />
									</Box>
								);
							})}
						</Slider>
					</Grid>
				</Grid>
			</Container>
		</Container>
	);
};
