import { Box, Card, CardContent, Typography } from '@mui/material';
import { IAdvertisingShow } from '@negotium/models';
import React from 'react';
import { ImageWithFallback } from '../ImageWithFallback';

type CardAdvertisingProps = {
	lng: 'es' | 'en';
	advertising: IAdvertisingShow;
};

const CardAdvertising = ({ lng, advertising }: CardAdvertisingProps) => {
	return (
		<Card
			sx={{
				background: '#ffffff',
				borderRadius: 5,

				width: { md: '90%', xs: '96%' },
				// minHeight: { md: '570px' },
				m: 'auto',
				mb: '20px',
				boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.4)',
			}}
		>
			<CardContent
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100%',
					p: 0,
				}}
			>
				<Box width={{ xs: '100%', md: '100%' }}>
					<ImageWithFallback
						src={advertising.image.url}
						fallbackImage='/static/facebook-lacuadra-600x315.png'
						alt={advertising.title[lng]}
						width={advertising.image.width}
						height={advertising.image.height}
						style={{
							aspectRatio: '1 / 1',
							width: '100%',
							height: 'auto',
						}}
					/>
				</Box>
				<Box
					sx={{
						p: 2,
						minHeight: { md: '170px', xs: '235px', sm: '200px' },
					}}
				>
					<Typography
						gutterBottom
						variant='BodySection'
						fontSize='clamp(0.7rem, 3vw, 1rem)'
						letterSpacing={1}
						fontWeight='600'
					>
						{advertising.title[lng]}
					</Typography>
					<Typography
						variant='ExplicationText'
						color='white.dark'
						fontSize='clamp(0.6rem, 3vw, 0.8rem)'
					>
						{advertising.description[lng]}
					</Typography>
				</Box>
			</CardContent>
		</Card>
	);
};

export default CardAdvertising;
